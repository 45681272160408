import React from 'react'
import { howToPropType } from 'lib/prop-types'
import PropTypes from 'prop-types'

const HowToStep = props => {
  let { step } = props

  return (
    <div
      className={`howto__item ${
        props.imagePosition ? `image-${props.imagePosition}` : ''
      }`}
    >
      <div className="howto__img">
        {step.image ? (
          <img src={step.image.url} alt={step.image.description} />
        ) : null}
        {step.text ? <div className="howto__overlay">{step.text}</div> : null}
      </div>
      <div className="howto__copy">
        <h2 className="mb2">{`${props.stepNumber + 1}. ${step.title}`}</h2>
        <div dangerouslySetInnerHTML={{ __html: step.description }} />
      </div>
    </div>
  )
}

HowToStep.propTypes = {
  step: howToPropType,
  stepNumber: PropTypes.number,
  imagePosition: PropTypes.string,
}

export default HowToStep
