import React from 'react'
import PropTypes from 'prop-types'
import { linkPropType } from 'lib/prop-types'
import Link from 'components/Link'
import contentfulUrlForLink from 'lib/contentful-url-for-link'

const Button = props => {
  let classNames = 'btn'
  if (props.className) {
    classNames += ` ${props.className}`
  }

  const buttonProps = {
    className: classNames,
  }
  if (props.onClick) {
    buttonProps.onClick = props.onClick
  }
  if (props.disabled) {
    buttonProps.disabled = props.disabled
  }
  if (props.state) {
    buttonProps.state = props.state
  }

  return props.link ? (
    <Link to={contentfulUrlForLink(props.link)} {...buttonProps}>
      {props.children}
    </Link>
  ) : (
    <button className={classNames} {...buttonProps}>
      {props.children}
    </button>
  )
}

export default Button

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  link: linkPropType,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  state: PropTypes.object,
}
