import React from 'react'
import { howToPropType } from 'lib/prop-types'
import PropTypes from 'prop-types'
import HowToStep from '../HowToStep'

const HowTo = props => {
  return (
    <div className="section section--padded-big">
      <div className="section__inner">
        <h2 className="headline tc">{props.title}</h2>
        {props.steps.map((step, i) => (
          <HowToStep
            key={`step${i}`}
            step={step}
            stepNumber={i}
            imagePosition={i % 2 === 0 ? 'left' : 'right'}
          />
        ))}
      </div>
    </div>
  )
}

HowTo.propTypes = {
  title: PropTypes.string.isRequired,
  steps: PropTypes.arrayOf(howToPropType).isRequired,
}

export default HowTo
