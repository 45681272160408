import React from 'react'
import PropTypes from 'prop-types'

const YouTubeLink = props => {
  const { videoId, start, title, description } = props
  const startQuery = start ? `?start=${start}` : ''
  return (
    <div
      className={
        props.fullSize
          ? 'article__media--item item--full'
          : 'article__media--item item--half'
      }
    >
      <div className="article__media--y-video">
        <iframe
          src={`https://www.youtube-nocookie.com/embed/${videoId}${startQuery}`}
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
      <h3 className="article__media--title">{title}</h3>
      {description ? (
        <p dangerouslySetInnerHTML={{ __html: description }} />
      ) : null}
    </div>
  )
}

export default YouTubeLink

YouTubeLink.propTypes = {
  videoId: PropTypes.string,
  start: PropTypes.string,
  title: PropTypes.string,
  description: PropTypes.string,
  fullSize: PropTypes.bool,
}
